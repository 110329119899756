<template>
    <div class="wrapper">
        <v-Logistics :wlInfoArr="wlInfoArr" />
    </div>
</template>

<script>
import { orderLogistics } from "../../../../../api";
import Logistics from "@/components/xinyu-logistics";
export default {
    components: {
        "v-Logistics": Logistics,
    },
    props: {},
    data() {
        return {
            wlInfoArr: [],
        };
    },
    watch: {},
    computed: {},
    methods: {
        orderLogistics() {
            orderLogistics(this.$route.query.id).then((res) => {
                var data = res.data.data.data;
                for (var item of data) {
                    item.logistics = JSON.parse(item.logistics)
                    var obj = null
                    if (item.logistics.message=="ok") {
                        obj = {
                            delivery_name: this.stateFun(item.logistics),
                            delivery_status: item.logistics.state,
                            post_name: item.companyName,
                            post_no: item.logisticsNo
                        };
                        for (var im of item.logistics.data) {
                            im.timeArr = im.time.split(' ')
                        }
                        obj.list = item.logistics.data
                    } else {
                        obj = {
                            post_name: item.companyName,
                            post_no: item.logisticsNo
                        };
                        obj.list = []
                    }
                    
                    this.wlInfoArr.push(obj)
                }
                
            });
        },
        stateFun(stream) {
            var str = "";
            var state = Number(stream.state);
            switch (state) {
                case 0:
                    str = "在途";
                    break;
                case 1:
                    str = "揽收";
                    break;
                case 2:
                    str = "疑难";
                    break;
                case 3:
                    str = "签收";
                    break;
                case 4:
                    str = "退签";
                    break;
                case 5:
                    str = "派件";
                    break;
                case 6:
                    str = "退回";
                    break;
                case 7:
                    str = "转投";
                    break;
                case 8:
                    str = "待清关";
                    break;
                case 9:
                    str = "揽收";
                    break;
                case 10:
                    str = "清关中";
                    break;
                case 11:
                    str = "已清关";
                    break;
                case 12:
                    str = "揽收";
                    break;
                case 13:
                    str = "清关异常";
                    break;
                case 14:
                    str = "拒签";
                    break;
            }
            return str
        },
    },
    created() {
        this.orderLogistics();
    },
    mounted() {},
};
</script>
<style scoped>
.wrapper {
}
</style>